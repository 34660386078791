import { collection, FirestoreCollectionReference } from "../Aliases";
import { AsyncTask } from "../Types/AsyncTask";
import { _ProjectAdditionalData } from "../Types/ElementCustomFields";
import { Mail } from "../Types/Mail";
import { Project } from "../Types/Project";
import { ProjectAssignment } from "../Types/ProjectAssignment";
import { ProjectShare } from "../Types/ProjectShare";
import { AssignmentCoachRelation } from "../Types/Relationships/AssignmentCoachRelation";
import { AssignmentInTrainingRelation } from "../Types/Relationships/AssignmentInTrainingRelation";
import { AssignmentLeaderRelation } from "../Types/Relationships/AssignmentLeaderRelation";
import {
  OneDriveSyncerConfig,
  SystemProjectsPublisherConfig,
} from "../Types/System/SystemTypes";
import { User } from "../Types/User";
import { UserAvailability } from "../Types/UserAvailability";
import { userAvailabilityConverter } from "../Types/UserAvailabilityConverter";
import { UserCredential } from "../Types/UserCredential";
import { SearchConfiguration } from "../Types/SearchConfiguration";
import { Post } from "../Types/News/Post";

export const adminUsersCollection = collection<User>("users");
export const adminProjectsCollection = collection<Project>("projects");
export const adminProjectAssignmentsCollection = collection<ProjectAssignment>(
  "project-assignments"
);
export const adminMailsCollection = collection<Mail>("mail");
export const adminProjectSharesCollection = collection<ProjectShare>(
  "share-projects"
);
export const adminProjectAdditionalDataCollection = collection<
  _ProjectAdditionalData
>("projects-additional-data");

export const adminUserCredentials = collection<UserCredential>("credentials");

export const adminAsyncTasksCollection = collection<AsyncTask<any>>(
  "async-tasks"
);

// User availability
export const adminUserAvailabilitiesCollection = collection<UserAvailability>(
  "user-availabilities"
).withConverter(
  userAvailabilityConverter as any
) as FirestoreCollectionReference<UserAvailability>;

// System docs
export const adminSystemAssignmentsExporterConfigDoc = collection<
  SystemProjectsPublisherConfig
>("system").doc("daemon-assignments-exporter");

// Relations
export const assignmentLeaderRelations = collection<AssignmentLeaderRelation>(
  "relation-assignment-leader"
);
export const assignmentCoachRelations = collection<AssignmentCoachRelation>(
  "relation-assignment-coach"
);
export const assignmentInTrainingRelations = collection<
  AssignmentInTrainingRelation
>("relation-assignment-inTraining");

// Search
export const adminSearchConfigurationDoc = collection<SearchConfiguration>(
  "_search"
).doc("configuration");

// News
export const postsCollection = collection<Post>("posts");

// System Config
export const oneDriveSyncerConfigDoc = collection<OneDriveSyncerConfig>(
  "system"
).doc("one-drive-syncer");
