import { Stack } from "@mui/material";
import { useAPICall, userQuery } from "../../utils/queries";
import PostPreview from "./PostPreview";
import LoadingIndicator from "../LoadingIndicator";
import { useAuthUser } from "next-firebase-auth";
import { Post, VisibilityType } from "~/Shared/Types/News/Post";
import { UserRole } from "~/Shared/Types/User";
import { FirestoreTimestamp } from "~/Shared/Aliases";
import { useEffect, useState } from "react";
import { GetNewsOutput } from "~/Shared/Types/API/Inputs";
import { DataProvider } from "~/utils/firebase/dataProvider";
import { deepConvertJSONToFirebaseTimestamps } from "~/utils/firebase/ParsingUtils";

function usePostsDataProvider(): DataProvider<Post> {
  const [getNewsCall] = useAPICall<{}, GetNewsOutput>("getNews");
  const [isLoading, setIsLoading] = useState(false);
  const [postsToShow, setPostsToShow] = useState<Post[]>([]);

  useEffect(() => {
    const load = async () => {
      setIsLoading(true);
      let output = await getNewsCall({});
      output = deepConvertJSONToFirebaseTimestamps(output);
      setPostsToShow(output.posts);
      setIsLoading(false);
    };
    load();
  }, []);

  return {
    objects: postsToShow,
    fetchNext: () => {
      return;
    },
    isLoading: isLoading,
    hasNextPage: false,
  };
}

export default function PostsList() {
  const postsDataProvider = usePostsDataProvider();
  const posts = postsDataProvider.objects;

  const authUser = useAuthUser();
  const myRole = (authUser.claims["role"] as UserRole) ?? UserRole.unverified;
  const isAdmin = myRole === UserRole.admin;

  return (
    <Stack spacing={13}>
      {postsDataProvider.isLoading ? <LoadingIndicator /> : null}
      {posts.map((post) => (
        <PostPreview key={post.id} post={post} isAdminMode={isAdmin} />
      ))}
    </Stack>
  );
}
