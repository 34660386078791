import Button from "@mui/material/Button";
import { Theme } from "@mui/material/styles";
import { createStyles } from "@mui/styles";
import { makeStyles } from "@mui/styles";
import AddIcon from "@mui/icons-material/Add";
import LoadingButton from "@mui/lab/LoadingButton";
import { useState } from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(1),
    },
  })
);

interface Props {
  title: string;
  addAction: () => Promise<any> | any;
}

export default function AddNewButton({ title, addAction }: Props) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  const clickAction = async () => {
    setIsLoading(true);
    try {
      await addAction();
    } catch {}
    setIsLoading(false);
  };

  return (
    <LoadingButton
      loading={isLoading}
      variant="contained"
      color="primary"
      className={classes.button}
      endIcon={<AddIcon />}
      onClick={clickAction}
    >
      {title}
    </LoadingButton>
  );
}
