import { max, min } from "date-fns";
import { FirestoreTimestamp } from "../Aliases";

export class TimestampRange {
  // Range is described as start..<end
  start: FirestoreTimestamp; // Closed
  end: FirestoreTimestamp; // Open

  constructor(
    start: FirestoreTimestamp | Date,
    end: FirestoreTimestamp | Date
  ) {
    if (start instanceof Date) {
      this.start = FirestoreTimestamp.fromDate(start);
    } else {
      this.start = start as any;
    }

    if (end instanceof Date) {
      this.end = FirestoreTimestamp.fromDate(end);
    } else {
      this.end = end as any;
    }
  }

  toFirestore() {
    return {
      start: this.start,
      end: this.end,
    };
  }

  contains(rangeB: TimestampRange): boolean {
    return this.start <= rangeB.start && rangeB.end <= this.end;
  }

  intersects(rangeB: TimestampRange): boolean {
    return (
      (this.start <= rangeB.start && this.end > rangeB.start) ||
      (this.end <= rangeB.end && this.end > rangeB.start)
    );
  }

  equals(rangeB: TimestampRange): boolean {
    return this.start.isEqual(rangeB.start) && this.end.isEqual(rangeB.end);
  }

  union(rangeB: TimestampRange): TimestampRange {
    const start = min([this.start.toDate(), rangeB.start.toDate()]);
    const end = max([this.end.toDate(), rangeB.end.toDate()]);
    return new TimestampRange(
      FirestoreTimestamp.fromDate(start),
      FirestoreTimestamp.fromDate(end)
    );
  }

  subtractOrSplit(
    rangeB: TimestampRange
  ): [TimestampRange | null, TimestampRange | null] {
    if (rangeB.start > this.start && rangeB.end < this.end) {
      // It's inside. So we need to split
      const leftRange = new TimestampRange(this.start, rangeB.start);
      const rightRange = new TimestampRange(rangeB.end, this.end);

      return [leftRange, rightRange];
    } else {
      let start = this.start;
      let end = this.end;

      if (rangeB.end > start && rangeB.start <= start) {
        // Trim from left
        start = rangeB.end;
      }

      if (rangeB.start < end && rangeB.end >= end) {
        // Trim from right
        end = rangeB.start;
      }

      if (start === end || start > end) {
        return [null, null];
      }

      const result = new TimestampRange(start, end);
      return [result, null];
    }
  }
}
