import { Typography, Stack } from "@mui/material";
import { Post } from "../../Shared/Types/News/Post";
import Link from "next/link";

interface Props {
  post: Post;
  isAdminMode: boolean;
}
export default function PostPreview({ post, isAdminMode }: Props) {
  const title = () => {
    return (
      <Typography variant="h4">
        <strong>{post.title}</strong>
      </Typography>
    );
  };
  return (
    <Stack spacing={2}>
      <Stack spacing={0}>
        {isAdminMode ? (
          <Link href={`/news/${post.id}`}>
            <a>{title()}</a>
          </Link>
        ) : (
          title()
        )}

        <Typography variant="caption">
          {post.createdAt.toDate().toLocaleDateString()}
        </Typography>
      </Stack>
      <Typography>
        <div dangerouslySetInnerHTML={{ __html: post.text }} />
      </Typography>
    </Stack>
  );
}
