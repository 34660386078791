import {
  AuthAction,
  useAuthUser,
  withAuthUser,
  withAuthUserTokenSSR,
} from "next-firebase-auth";
import Head from "next/head";
import React, { useState } from "react";
import AdminApp from "../../components/apps/AdminApp";
import { Container, Section } from "../../components/Generic/Layout";

import { Stack } from "@mui/material";
import PageHeader from "../../components/Generic/PageHeader";
import AddNewButton from "../../components/AddNewButton";
import { useRouter } from "next/router";
import PostsList from "../../components/news/PostsList";
import { Post, VisibilityType } from "../../Shared/Types/News/Post";
import { now } from "../../utils/firebase";
import Image from "next/image";
import { UserRole } from "~/Shared/Types/User";
import { postsCollection } from "~/Shared/Utils/FirestoreCollections";
import { useIntl } from "react-intl";

function _useStrings() {
  const intl = useIntl();
  const STRINGS = {
    ADD_NEWS_BTN_TITLE: intl.formatMessage({
      id: "add-new-btn-title",
      defaultMessage: "Nowy Komunikat",
    }),
  };
  return STRINGS;
}

const _NewsPage = () => {
  return (
    <AdminApp>
      <Head>
        <title>Komunikaty</title>
      </Head>
      <NewsPage />
    </AdminApp>
  );
};

function NewsPage() {
  const router = useRouter();
  const STRINGS = _useStrings();
  const authUser = useAuthUser();
  const isAdmin = authUser.claims["role"] === UserRole.admin;
  const addNew = async () => {
    const postDoc = postsCollection.doc();
    const newPost: Post = {
      id: postDoc.id,
      createdAt: now(),
      postedBy: authUser.id!,

      title: "Komunikat",
      text: "",
      visiblity: {
        type: VisibilityType.hidden,
        start: null,
        end: null,
      },
    };
    await postDoc.set(newPost);
    await router.push(`/news/${newPost.id}`);
  };
  return (
    <Stack spacing={1}>
      <div
        style={{
          height: 250,
          position: "relative",
          borderRadius: 10,
          overflow: "hidden",
        }}
      >
        <Image
          layout="fill"
          src="https://firebasestorage.googleapis.com/v0/b/ipzin-archipelag.appspot.com/o/website%2Fipzin-header.jpg?alt=media&token=095fe902-1684-49e0-bc70-c14c571811a5"
          objectFit="cover"
        />
      </div>
      {isAdmin ? (
        <PageHeader>
          <div className="spacer" />
          <AddNewButton title={STRINGS.ADD_NEWS_BTN_TITLE} addAction={addNew} />
        </PageHeader>
      ) : null}

      <Section>
        <Container>
          <div className="flexV alignLeft" style={{ rowGap: "20px" }}>
            <PostsList />
          </div>
        </Container>
      </Section>
    </Stack>
  );
}

export const getServerSideProps = withAuthUserTokenSSR()();
export default withAuthUser({
  whenUnauthedAfterInit: AuthAction.REDIRECT_TO_LOGIN,
})(_NewsPage);
