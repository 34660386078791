import firebase from "firebase/app";
import lodash from "lodash";

export function deepConvertJSONToFirebaseTimestamps(val: any) {
  if (lodash.isArray(val)) {
    for (const item of val) {
      deepConvertJSONToFirebaseTimestamps(item);
    }
  } else if (lodash.isObject(val)) {
    for (const key of Object.keys(val)) {
      const deepVal = lodash.get(val, key);

      if (deepVal) {
        const sec = deepVal["_seconds"];
        const nano = deepVal["_nanoseconds"];
        if (sec !== undefined && nano !== undefined) {
          const timestamp = new firebase.firestore.Timestamp(sec, nano);
          lodash.set(val, key, timestamp);
        } else {
          deepConvertJSONToFirebaseTimestamps(deepVal);
        }
      }
    }
  }
  return val;
}
