import { UserAvailability } from "./UserAvailability";
import { TimestampRange } from "./TimestampRange";
// import {FirestoreDataConverter} from "@google-cloud/firestore"

export const userAvailabilityConverter = {
  toFirestore: (availability: UserAvailability) => {
    return {
      id: availability.id,
      userId: availability.userId,
      range: availability.range.toFirestore(),
    };
  },
  fromFirestore: (snapshot: any, options: any) => {
    const data = snapshot.data(options);
    const a: UserAvailability = {
      id: data.id,
      userId: data.userId,
      range: new TimestampRange(data.range.start, data.range.end),
    };

    return a;
  },
};
