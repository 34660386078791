import { FirestoreTimestamp } from "../../Aliases";
import { UserRole } from "../User";

export interface Post {
  id: string;
  createdAt: FirestoreTimestamp;

  postedBy: string; // user-id
  title: string;
  text: string;

  visiblity: {
    type: VisibilityType;
    start: FirestoreTimestamp | null;
    end: FirestoreTimestamp | null;

    assignmentIds?: string[];
    roles?: UserRole[];
    userIds?: string[];
  };
}

export enum VisibilityType {
  hidden = "hidden",

  assignments = "assignments",
  roles = "roles",
  users = "users",
}
