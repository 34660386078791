export class Keys {
  GOOGLE_CLIENT_ID: string;
  GOOGLE_CLIENT_SECRET: string;

  constructor() {
    this.GOOGLE_CLIENT_ID = process.env.GOOGLE_CLIENT_ID as string;
    this.GOOGLE_CLIENT_SECRET = process.env.GOOGLE_CLIENT_SECRET as string;
  }

  private static instance: Keys;
  public static shared(): Keys {
    if (!Keys.instance) {
      Keys.instance = new Keys();
    }
    return Keys.instance;
  }
}
